<template>
  <div>
    <!--  面包屑  -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>库存管理</el-breadcrumb-item>
      <el-breadcrumb-item>库存调拨</el-breadcrumb-item>
      <el-breadcrumb-item>新建库存调拨</el-breadcrumb-item>
    </el-breadcrumb>

    <el-card class="box-card">
      <el-form  :model="addwarehouseallocationForm" :rules="addwarehouseallocationFormRules" ref="addwarehouseallocationFormRef"
                label-width="100px">
        <el-row>
          <el-col>
            <el-form-item label="调出仓库" prop="warehouselist">
              <el-cascader
                  :options="TreeList" v-model="addwarehouseallocationForm.warehouselist"
                  :props="Treeprops" @change="getcheckList" placeholder="请选择调出仓库"
                  clearable></el-cascader>
            </el-form-item>
          </el-col>
          <el-col>
            <el-button @click="add" type="primary" :disabled="adddisabled">添加</el-button>
          </el-col>
        </el-row>
        <el-form-item label-width="0px">
          <el-table :data="addwarehouseallocationForm.goodslist" border stripe  >
            <el-table-column type="index"></el-table-column>
            <el-table-column label="商品编码" prop="ga_code"></el-table-column>
            <el-table-column label="商品名称" prop="g_name"></el-table-column>
            <el-table-column label="商品规格" prop="ga_name"></el-table-column>
            <el-table-column label="单位" prop="gu_name"></el-table-column>
            <el-table-column label="库存" prop="ga_stock"></el-table-column>
            <el-table-column label="调库数量" prop="prop">
              <template slot-scope="scope">
                <el-input v-model="scope.row.wai_number" ></el-input>
              </template>
            </el-table-column>
          </el-table>
        </el-form-item>
        <el-form-item label="调入仓库" prop="warehouselist">
          <el-cascader
              :options="TreeList" v-model="addwarehouseallocationForm.warehouselists"
              :props="Treeprops" @change="getchecktwoList" placeholder="请选择调入仓库"
              clearable></el-cascader>
        </el-form-item>
        <el-form-item label="经办人：" prop="p_purchase_id">
          <el-select v-model="addwarehouseallocationForm.wa_agent" placeholder="请选择经办人"
                     clearable class="p_purchase_id">
            <el-option
                v-for="item in userlist"
                :key="item.m_id"
                :label="item.m_name"
                :value="item.m_id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="调库时间：" prop="wa_date">
          <el-date-picker v-model="addwarehouseallocationForm.wa_date" class="p_date"
                          type="date" value-format="yyyy-MM-dd" placeholder="选择日期">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="调库备注：" prop="wa_remark">
          <el-input v-model="addwarehouseallocationForm.wa_remark" class="p_remark" placeholder="请输入备注"></el-input>
        </el-form-item>
        <!--        提交-->
        <div style="float: right; padding-bottom:20px; ">
          <el-button @click="addgo">取消</el-button>

          <el-button type="primary" @click="addupPurchase" :loading="addloading">确定</el-button>
        </div>
      </el-form>

    </el-card>
    <el-dialog  title="选择商品" :visible.sync="addGoodsDialogVisible"
                width="70%">
      <el-row :gutter="30">
        <el-col :span="6">
          <el-input placeholder="请输入内容" v-model="querInfo.g_name" clearable @clear="getGoodsList(querInfo)">
            <el-button slot="append" icon="el-icon-search" @click="getGoodsList(querInfo)"></el-button>
          </el-input>
        </el-col>
        <el-col :span="6">
          <el-cascader v-model="selectefKeys" :options="categorieslList" clearable placeholder="请选择商品分类"
                       :props="cascaderPropscate" @change="parentCateChanged"></el-cascader>
        </el-col>
      </el-row>
      <el-table :data="Goodslist" stripe @select-all="changeFun" @select="asd" ref="docTable">
        <el-table-column type="selection" width="55" ></el-table-column>
        <!--        <el-table-column></el-table-column>-->
        <el-table-column label="商品编码" prop="ga_code"></el-table-column>
        <el-table-column label="商品名称" prop="g_name"></el-table-column>
        <el-table-column label="商品规格" prop="ga_name">
          <template slot-scope="scope" >
            <div>{{scope.row.ga_name}}</div>
            <div v-if="scope.row.ga_start_date">{{scope.row.ga_start_date}}{{scope.row.ga_end_date}}</div>
          </template>
        </el-table-column>
        <el-table-column label="单位" prop="gu_name" width="120px"></el-table-column>
        <el-table-column label="质量等级" prop="ql_name" width="120px"></el-table-column>
      </el-table>
      <!--分页-->
      <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="querInfo.page"
          :page-sizes="[3,5,10,15]"
          :page-size="querInfo.limit"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total">
      </el-pagination>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="addgoods">添加商品</el-button>
        <el-button @click="addGoodsDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="addGoodsDialogVisible = false">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
export default{
  data(){
    return{
      addloading:false,
      querInfo: {
        page: 1,
        limit: 10,
        's_id':'',
        g_cid:''
      },
      total:0,
      //仓库列表
      TreeList:[],
      //用户列表
      userlist:[],
      Treeprops:{
        // multiple: true,
        // checkStrictly: true,
        expandTrigger: 'hover',
        // checkStrictly:true,
        value: 'w_id',
        label: 'w_name',
        children: 'children'},
      //添加按钮的禁用与启用
      adddisabled:true,
      //控制添加商品的显示或隐藏
      addGoodsDialogVisible:false,
      //添加表单的form表单
      addwarehouseallocationForm:{
        goodslist:[],
        wa_date:this.$store.state.daydate,
        wa_agent:this.$store.state.m_id
      },
      addwarehouseallocationFormRules: {
        s_company_name: [
          {required: true, message: '请输入供应商的名称', trigger: 'blur'}
        ],
      },
      //商品分类list
      categorieslList:[],
      //指定级联选择器的配置对象
      cascaderPropscate: {
        expandTrigger: 'hover',
        // checkStrictly:true,
        value: 'c_id',
        label: 'c_name',
        children: 'children'
      },
      //选中的父级分类的ID数组
      selectefKeys: [],
      //选中的商品列表
      Goodslist:[],
    }
  },
  created() {
    this.showSetTreeListDialog()
    this.getcateList()
    this.getuserList()
  },
  methods:{
    //获取仓库数据
    async showSetTreeListDialog() {
      //获取所有权限的数据
      const {data: res} = await this.$http.get('warehouse/getTreeList')
      if (res.code != 200) return this.$message.error(res.msg)
      this.TreeList = res.data
    },
    //获取商品数据
    async getGoodsList(querInfo){
      const {data:res} = await this.$http.get('goods/getList',
          {params:querInfo})
      if (res.code !== 200) {
        return this.$message.error(res.msg)
      }
      this.Goodslist=res.data.data
      this.total=res.data.total
      setTimeout(() => {
        this.pickUp() //需要默认勾选的数据
      }, 10)
    },
    //获取商品分类
    async getcateList() {
      const {data: res} = await this.$http.get('category/getMainList/3')
      if (res.code !== 200) {
        return this.$message.error(res.msg)
      }
      // 把数据列表 赋值
      this.categorieslList = res.data
    },
    //获取采购员
    async getuserList() {
      const {data: res} = await this.$http.get('member/getMainList')
      if (res.code !== 200) {
        return this.$message.error(res.msg)
      }
      this.userlist=res.data
    },
    //监听pagesize改变
    handleSizeChange(newSize) {
      this.querInfo.limit = newSize
      this.getGoodsList(this.querInfo)
    },
    //监听pagenum 改变
    handleCurrentChange(newPage) {
      this.querInfo.page = newPage
      this.getGoodsList(this.querInfo)
    },

    //商品分类
    parentCateChanged() {
      this.querInfo.g_cid=this.selectefKeys[this.selectefKeys.length-1]
      console.log(this.querInfo.g_cid)
      this.getGoodsList(this.querInfo)
    },
    //供应商选项框发生变化时的回调函数
    getcheckList(){
      if (this.addwarehouseallocationForm.warehouselist == []){
        console.log(this.addwarehouseallocationForm.warehouselist,'等于空')
        this.addwarehouseallocationForm.goodslist=[]
        this.adddisabled=true
        return true
      }else{
        console.log('有值')
        this.addwarehouseallocationForm.w_out_id=this.addwarehouseallocationForm.warehouselist[0]
        this.addwarehouseallocationForm.wp_out_id=this.addwarehouseallocationForm.warehouselist[1]
        this.querInfo.w_id=this.addwarehouseallocationForm.warehouselist[0]
        this.querInfo.wp_id=this.addwarehouseallocationForm.warehouselist[1]
        // this.id.s_id=this.ss_id
        this.addwarehouseallocationForm.goodslist=[]
        this.getGoodsList(this.querInfo)
        this.adddisabled=false
      }
    },
    getchecktwoList(){
        this.addwarehouseallocationForm.w_in_id=this.addwarehouseallocationForm.warehouselists[0]
        this.addwarehouseallocationForm.wp_in_id=this.addwarehouseallocationForm.warehouselists[1]
    },
    changeFun(selection) {
      let count = 0;
      this.Goodslist.forEach((items,indexs)=>{
        this.addwarehouseallocationForm.goodslist.forEach((item,index)=>{
          if (items.ga_id === item.ga_id){
            this.addwarehouseallocationForm.goodslist.splice(index, 1)
            count++;
          }
        })
      })
      if(count != this.querInfo.limit){
        this.Goodslist.forEach((item,index)=>{
          this.asd([],item)
        })
      }else{
        count = 0;
      }
    },
    asd(selection, row) {
      let is_exists = true;
      this.addwarehouseallocationForm.goodslist.forEach((item,index)=>{
        if (this.addwarehouseallocationForm.goodslist[index].ga_id===row.ga_id) {
          is_exists = false;
          this.addwarehouseallocationForm.goodslist.splice(index, 1)
        } else {
          // is_exists = false;
        }
      })
      if (is_exists) {
        this.addwarehouseallocationForm.goodslist.push(row)
        is_exists = true;
      }
    },
    // 需要默认勾选的数据
    pickUp() {
      let arr = []
      this.Goodslist.forEach((item) => {
        this.addwarehouseallocationForm.goodslist.forEach((val) => {
          if (val.ga_id === item.ga_id) {
            arr.push(item)
            console.log(arr,'123456')
            // this.radios.push(item.roleID)
          }
        })
      })
      this.toggleSelection(arr)
    },
    // 默认选择中的行
    toggleSelection(rows) {
      console.log(rows)
      if (rows) {
        rows.forEach((row) => {
          this.$refs.docTable.toggleRowSelection(row)
        })
      } else {
        this.$refs.docTable.clearSelection()
      }
    },

    add(){
      this.addGoodsDialogVisible=true
    },
    //添加商品
    addgoods(){
      console.log(this.addwarehouseallocationForm.s_id)
      this.$router.push({
        path:"/goods/addgoods",
        query:{id:this.addwarehouseallocationForm.s_id,typeindex:1}
      })
    },
    addgo(){
      this.$router.go(-1)
    },
    //提交form表单
    async addupPurchase(){
      this.$refs.addwarehouseallocationFormRef.validate(async valid => {
        if (!valid) return
        this.addloading=true
        const {data: res} = await this.$http.post('warehouseallocation/add', this.addwarehouseallocationForm)
        this.addloading=false
        if (res.code !== 200) return this.$message.error(res.msg)
        this.$message.success(res.msg)
        this.$router.go(-1)
      })
    },
  }

}
</script>
<style scoped>
.el-select,.el-cascader,.p_date,.p_remark{
  width: 300px;
}
</style>
